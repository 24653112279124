<template>
    <div>
      <ServerError v-if="ServerError" />
      <PageLoader :storage="appLoading" />
      <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: #fff">{{ msg }}</span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: #fff">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout wrap justify-center pa-3>
        <v-flex xs12>
          <v-layout wrap justify-start>
            <v-flex xs12>
              <span class="itemHeading1"
                >Release Case
                <span v-if="animalname && locationnameofincident"
                  >: {{ animalname }},{{ locationnameofincident }}({{
                    formattedReportedDate
                  }})</span
                ></span
              >
            </v-flex>
          </v-layout>
          <v-layout wrap justify-center pt-3>
            <v-flex xs12 lg7 pr-3>
              <v-layout wrap justify-start>
                <v-flex xs12>
                  <v-text-field
                    outlined
                    append-icon="mdi-magnify"
                    append-outer-icon="mdi-close"
                    label="Location Name"
                    placeholder="Enter Location Name"
                    v-model="locationname"
                    @click:append="plotLocation"
                    @click:append-outer="resetMap"
                    class="itemform"
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md6 sm6 lg6>
                  <v-text-field
                    outlined
                    label="Latitude"
                    v-model="lattitude"
                    disabled
                    class="itemform"
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md6 sm6 lg6 pl-lg-2 pl-md-2 pl-sm-2>
                  <v-text-field
                    outlined
                    label="Longitude"
                    v-model="longitude"
                    disabled
                    class="itemform"
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md6 sm6>
                  <v-select
                    :items="animalHealthTypes"
                    label="Animal Health"
                    outlined
                    item-text="name"
                    item-value="_id"
                    v-model="animalhealth"
                    class="itemform"
                    dense
                  ></v-select>
                </v-flex>
                <v-flex xs12 md6 sm6 lg6 pl-lg-2 pl-md-2 pl-sm-2>
                  <v-select
                    :items="MishapTypes"
                    label="Mishaps"
                    outlined
                    v-model="mishaps"
                    class="itemform"
                    dense
                  ></v-select>
                </v-flex>
                <v-flex xs12 md6 sm6 lg6>
                  <v-select
                    :items="IsDelayedTypes"
                    label="Is Delayed?"
                    outlined
                    class="itemform"
                    dense
                    v-model="isdelayed"
                  ></v-select>
                </v-flex>
              </v-layout>
              <v-layout wrap justify-center>
                <v-flex xs12 v-if="isdelayed === 'Yes'">
                  <v-textarea
                    outlined
                    name="input-7-4"
                    label="Reason For Delay"
                    v-model="delayreason"
                    class="itemform"
                    dense
                  ></v-textarea>
                </v-flex>
                <v-flex xs12>
                  <v-textarea
                    outlined
                    name="input-7-4"
                    label="Remarks"
                    v-model="remarks"
                    class="itemform"
                    dense
                  ></v-textarea>
                </v-flex>
              </v-layout>
              <v-layout wrap justify-center pa-5 style="border: solid 1px">
                <v-flex xs12 text-left>
                  <v-layout wrap justify-start>
                    <v-flex
                      xs12
                      sm6
                      md4
                      lg4
                      pa-5
                      v-for="(items, index) in displayImage"
                      :key="index"
                    >
                      <v-img :src="items" fill-height height="100%" width="100%">
                        <v-layout wrap>
                          <v-flex text-right pa-0>
                            <v-avatar color="#FF3434" size="15">
                              <v-icon
                                color="#FFF"
                                small
                                @click="removeImage(index)"
                              >
                                mdi-close
                              </v-icon>
                            </v-avatar>
                          </v-flex>
                        </v-layout>
                      </v-img>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap justify-end>
                    <v-flex xs12 text-right>
                      <v-btn
                        color="blue-grey"
                        dark
                        small
                        @click="$refs.uploadDoc.click()"
                        class="itemform"
                        ><span> UPLOAD IMAGES </span>
                        <v-icon right dark> mdi-cloud-upload </v-icon></v-btn
                      >
                    </v-flex>
                    <input
                      v-show="false"
                      id="file1"
                      ref="uploadDoc"
                      multiple
                      type="file"
                      accept=".svg, .png, .jpg, .jpeg"
                      @change="uploadDoc"
                    />
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout wrap justify-center pt-3 pb-3>
            <!-- <v-flex xs6 class="text-center">
              <v-btn color="error" @click="resetMap">
                <span>RESET MAP</span>
              </v-btn>
            </v-flex> -->
            <v-flex xs12 class="text-center">
                  <v-btn
                    block
                    tile
                    :color="appColor"
                    light
                    :ripple="false"
                    depressed
                    @click="handleSubmit"
                    class="itemValue"
                  >
                    <span style="color: #fff">SUBMIT</span>
                  </v-btn>
                </v-flex>
          </v-layout>
            </v-flex>
            <v-flex xs12 lg5 pr-3>
              <v-layout wrap justify-center>
            <v-flex xs12>
              <div id="mapp"></div>
            </v-flex>
          </v-layout>
            </v-flex>
          </v-layout>
          <!-- <v-layout wrap justify-center pa-3>
            <v-flex xs6 class="text-center">
              <v-btn color="error" @click="resetMap">
                <span>RESET MAP</span>
              </v-btn>
            </v-flex>
            <v-flex xs6 class="text-center">
              <v-btn color="success" @click="handleSubmit">
                <span>SUBMIT</span>
              </v-btn>
            </v-flex>
          </v-layout> -->
          <!-- <v-layout wrap justify-center>
            <v-flex xs12 lg12>
              <div id="mapp"></div>
            </v-flex>
          </v-layout> -->
        </v-flex>
      </v-layout>
    </div>
  </template>
      
      <script src="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyDPNXCjI6IJbDqwJcJwGuJUfNWxfMLSe-U&libraries=visualization,drawing"></script>
      <script>
  export default {
    data() {
      return {
        appLoading: false,
        ServerError: false,
        showSnackBar: false,
        panCardImages: [],
        lattitude: "",
        longitude: "",
        locationname: "",
        animaltype: "",
        situation: "",
        delayreason: "",
        msg: "",
        timeout: 5000,
        threatlevel: "",
        animalTypes: [
          "Elephant",
          "Tiger",
          "Leopard",
          "Wild Pig",
          "Monkey",
          "Snake",
          "Others",
          "Malabar Giant Squirrel",
          "Gaur",
          "Sambar",
          "Spotted Deer",
          "Wild Dog",
          "Others",
        ],
        IsDelayedTypes: ["Yes", "No"],
        isdelayed: "",
        threatLevels: ["High", "Medium", "Low"],
        map: "",
        mapData: {},
        checkboxItems: [],
        MishapTypes: ["Test1", "Test2"],
        mishaps: "",
        selectedItems: [],
        filterData: [],
        lat: "",
        lon: "",
        onMarker: [],
        map3: "",
        categorylist2: [],
        selected: "",
        newMarker: [],
        animalname: this.$route.query.name,
        locationnameofincident: this.$route.query.location,
        reporteddate: this.$route.query.date,
        defaultChecked: true,
        geocoder: null,
        marker: null,
        animalhealth: "",
        remarks:"",
        animalHealthTypes: [
        ],
        docs1: [],
        docs1Public: [],
        docsPublic: [],
        docs: [],
        displayImage: [],
        userList: [],
        userArray: [],
        newArray: [],
        doc: null,
        file: null,
        keyword: "",
        registerAssets: {},
        formData: new FormData(),
        assetId: "",
        search: "",
        Rules: [(value) => !!value || "Required."],
        fileArray: [],
        byte: "",
        kb: "",
        mb: "",
        gb: "",
      };
    },
    mounted() {
      this.mapData = this.storage;
      this.initMap();
      this.getanimalstatusData();
    },
    computed: {
      formattedReportedDate() {
        if (!this.reporteddate) return "";
        const date = new Date(this.reporteddate);
        const day = String(date.getUTCDate()).padStart(2, "0");
        const month = String(date.getUTCMonth() + 1).padStart(2, "0");
        const year = date.getUTCFullYear();
        return `${day}/${month}/${year}`;
      },
    },
    methods: {
      uploadDoc(event) {
        this.docs1 = event.target.files || event.dataTransfer.files;
  
        if (this.docs.length < 10) {
          for (let i = 0; i < this.docs1.length; i++) {
            if (this.fileArray.includes(this.docs1[i].name)) {
              this.msg = "Duplicate File";
              this.showSnackBar = true;
            } else {
              const urls = URL.createObjectURL(this.docs1[i]);
              this.displayImage.push(urls);
              this.docs.push(this.docs1[i]);
              console.log("file", this.docs1[i]);
              console.log("haii img", this.docs);
            }
            this.fileArray.push(this.docs1[i].name);
            this.checkbox = false;
            // this.checkbox1 = false;
            this.checkbox3 = false;
            // this.checkbox2 = false;
            this.docs1[i].sizeCopy = this.docs1[i].size + "Bytes";
            if (this.docs1[i].size >= 1024) {
              this.byte = this.docs1[i].size;
              this.kb = this.byte / 1024;
              if (this.kb >= 1024) {
                this.mb = (this.byte / 1048576).toFixed(2);
                if (this.mb >= 1024) {
                  this.gb = (this.byte / 1073741824).toFixed(2);
                  this.docs1[i].sizeCopy = this.gb + "GB";
                } else {
                  this.docs1[i].sizeCopy = this.mb + "MB";
                }
              } else {
                this.docs1[i].sizeCopy = this.kb.toFixed(2) + "KB";
              }
            }
          }
          // this.addToArray();
        } else {
          this.msg = "Maximum 10 can be uploaded";
          this.showSnackBar = true;
        }
      },
      removeImage(index) {
        this.displayImage.splice(index, 1);
        this.docs.splice(index, 1);
        this.fileArray.splice(index, 1);
      },
      initMap() {
        this.map3 = new google.maps.Map(document.getElementById("mapp"), {
          center: { lat: 10.8505, lng: 76.2711 },
          zoom: 7,
        });
        this.geocoder = new google.maps.Geocoder();
      },
      plotLocation() {
        if (this.locationname) {
          this.geocoder.geocode(
            { address: this.locationname },
            (results, status) => {
              if (status === "OK") {
                const location = results[0].geometry.location;
                this.addMarker(location);
                this.map3.setCenter(location);
  
                // Update latitude and longitude fields
                this.lattitude = location.lat();
                this.longitude = location.lng();
              } else {
                console.error(
                  "Geocode was not successful for the following reason: " + status
                );
              }
            }
          );
        }
      },
      addMarker(location) {
        if (this.marker) {
          // If a marker already exists, update its position
          this.marker.setPosition(location);
        } else {
          // Create a new marker
          this.marker = new google.maps.Marker({
            position: location,
            map: this.map3,
            title: this.locationname,
            draggable: true,
          });
          this.onMarker.push(this.marker);
        }
  
        // Update fields when marker is dragged
        google.maps.event.addListener(this.marker, "dragend", (event) => {
          const newLocation = event.latLng;
          this.lattitude = newLocation.lat();
          this.longitude = newLocation.lng();
          this.reverseGeocode(newLocation);
        });
      },
      reverseGeocode(location) {
        this.geocoder.geocode({ location: location }, (results, status) => {
          if (status === "OK" && results[0]) {
            const address = results[0].formatted_address;
            this.locationname = address;
          } else {
            console.error(
              "Geocode was not successful for the following reason: " + status
            );
          }
        });
      },
      resetMap() {
        // Clear the marker from the map
        if (this.marker) {
          this.marker.setMap(null);
          this.marker = null;
        }
  
        // Clear the fields
        this.lattitude = "";
        this.longitude = "";
        this.locationname = "";
        this.panCardImages = [];
      },
      toggleSublist(index) {
        this.checkboxItems[index].sublistVisible =
          !this.checkboxItems[index].sublistVisible;
      },
      addMarkers(projects) {
        const infowindow = new google.maps.InfoWindow();
  
        projects.forEach((project) => {
          project.allLocations.forEach((location) => {
            const lat = location.location[1];
            const lon = location.location[0];
            const locationLatLng = new google.maps.LatLng(lat, lon);
  
            const marker = new google.maps.Marker({
              position: locationLatLng,
              map: this.map3,
              title: location.place,
              id: project._id,
              icon: project.icon
                ? {
                    url: `https://wtiadmin.in/file/get/${project.icon}`,
                    scaledSize: new google.maps.Size(40, 40),
                  }
                : null, // Use default marker icon if no icon is present
            });
            this.onMarker.push(marker);
            this.newMarker.push({
              id: project._id,
              position: locationLatLng,
            });
  
            // Add event listener for click to display location details
            marker.addListener("click", () => {
              const description = project.description
                ? project.description
                : "No description available";
              let content = `
                <div style="max-width: 300px; max-height: 250px; overflow-y: auto;">
                  <h3 style="font-size: 16px; justify-content: space-between;">${location.place}</h3>
                  <p>${description}</p>
              `;
  
              if (project.url) {
                content += `<a href="${project.url}" target="_blank" style="font-size: 12px;"><h4>Read more</h4></a>`;
              }
  
              content += `</div>`;
  
              infowindow.setContent(content);
              infowindow.open(this.map3, marker);
            });
  
            // Close the info window when clicking outside the marker
            google.maps.event.addListener(this.map3, "click", function () {
              infowindow.close();
            });
          });
        });
      },
      clearMarkers(checkboxId) {
        const isMainCategory = this.checkboxItems.some(
          (item) => item._id === checkboxId
        );
        const isSubCategory = this.checkboxItems.some(
          (item) =>
            item.projects &&
            item.projects.some((subItem) => subItem._id === checkboxId)
        );
  
        if (isMainCategory || isSubCategory) {
          var Marker = this.newMarker.filter((x) => x.id == checkboxId);
          for (let j = 0; j < Marker.length; j++) {
            for (let i = 0; i < this.onMarker.length; i++) {
              if (this.onMarker[i].id == Marker[j].id) {
                this.onMarker[i].setMap(null);
                this.onMarker.splice(i, 1);
                break;
              }
            }
          }
        }
      },
      clearAllMarkers() {
        for (let i = 0; i < this.onMarker.length; i++) {
          this.onMarker[i].setMap(null);
        }
        this.onMarker = [];
        this.newMarker = [];
      },
      getanimalstatusData() {
        this.appLoading = true;
        axios({
          method: "GET",
          url: "/animalStatus/list",
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              {
                this.animalHealthTypes = response.data.data;
                this.appLoading = false;
              }
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      handleSubmit() {
        if (!this.locationname) {
          this.msg = "Please Provide Location Name.";
          this.showSnackBar = true;
          return;
        }
        if (!this.lattitude) {
          this.msg = "Please Provide Latitude.";
          this.showSnackBar = true;
          return;
        }
        if (!this.longitude) {
          this.msg = "Please Provide Longitude.";
          this.showSnackBar = true;
          return;
        }
        if (this.docs.length === 0) {
          this.msg = "Please Provide Images Of Releasing The Animal.";
          this.showSnackBar = true;
          return;
        }
        if (this.isdelayed === 'Yes' && !this.delayreason) {
          this.msg = "Please Provide Reason For Delay.";
          this.showSnackBar = true;
          return;
        }
        const formData = new FormData();
        
        formData.append("locationName", this.locationname);
        formData.append("lat", this.lattitude);
        formData.append("lon", this.longitude);
        formData.append("reportId", this.$route.query.id);
        formData.append("mishaps", this.mishaps);
        formData.append("animalHealth", this.animalhealth);
        if(this.isdelayed === 'Yes'){
          formData.append("isDelayed", true);
          formData.append("delayreason", this.delayreason);
        }
        formData.append("remarks", this.remarks)
        for (let i = 0; i < this.docs.length; i++) {
          formData.append("releaseImages", this.docs[i]);
        }
        this.appLoading = true;
        axios({
          method: "POST",
          url: "/report/resolved/release",
          headers: {
            "Content-Type": "multipart/form-data",
            "x-auth-token": localStorage.getItem("token"),
          },
          data: formData,
        })
          .then((response) => {
            if (response.data.status == true) {
              {
                this.msg = response.data.msg;
                this.showSnackBar = true;
                this.appLoading = false;
                // this.threatlevel = "";
                // this.locationname = "";
                // this.situation = "";
                // this.animalhealth = "";
                // this.lattitude = "";
                // this.longitude = "";
                // this.time = "";
                // this.docs = [];
                // this.mishaps = "";
                // this.marker.setMap(null);
                // this.marker = null;
                // window.location.reload();

                // this.$router.push({
                //   path: "/admin/adminDashboard?id=releasedCases",
                // });
                this.$router
                .push({
                  path: "/admin/adminDashboard?id=releasedCases",
                })
                .then(() => {
                  window.location.reload();
                });
              }
            } else {
              this.appLoading = false;
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
    },
  };
  </script>
      
      <style>
  #mapp {
    height: 577px !important;
    width: 100%;
  }
  .uploaded-image {
    position: relative;
    display: inline-block;
    margin-right: 10px;
  }
  
  .uploaded-image img {
    max-width: 100%;
    max-height: 200px;
  }
  
  .uploaded-image v-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    background: rgba(255, 255, 255, 0.8);
  }
  </style>
      